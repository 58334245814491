<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>课程</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="course-notlogin">
      <div
        class="course-notlogin-list"
        v-for="item in licenceData"
        :key="item.licence_id"
      >
        <h2 class="title">
          <span>{{ item.licence_name }}学习</span>
        </h2>
        <p>{{ item.describe }}</p>
        <el-row>
          <el-col
            :span="4"
            v-for="value in item.children"
            :key="value.licence_name"
          >
            <div class="course-list-item">
              <span class="icon" :class="value.classname"></span>
              <!-- <span
                ><img :src="value.mobile_imgurl" :alt="value.licence_name"
              /></span> -->
              <h3>{{ value.licence_name }}</h3>
              <el-button size="medium">
                <router-link
                  :to="{
                    path: '/catalogs/shikan?licence_id=' + value.licence_id
                  }"
                  tag="a"
                  target="_blank"
                >
                  体验课程
                </router-link>
              </el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getlicence } from '../api/licence'

export default {
  name: 'Home',
  data() {
    return {
      dataInfo: [
        {
          id: 1,
          title:
            '交通运输部办公厅关于认真贯彻《机动车驾驶培训教学与考试大纲》的通知'
        },
        {
          id: 2,
          title:
            '交通运输部办公厅关于认真贯彻《机动车驾驶培训教学与考试大纲》的通知2'
        },
        {
          id: 3,
          title:
            '交通运输部办公厅关于认真贯彻《机动车驾驶培训教学与考试大纲》的通知3'
        }
      ],
      licenceData: []
      // courseList: [
      //   {
      //     id: 1,
      //     title: '驾驶证',
      //     describe: '视频课程符合最新教学与考试大纲，内容全面、专业、权威。',
      //       children: [
      //         {
      //           name: '小车',
      //           icon: 'icon-car2'
      //         },
      //         {
      //           name: '汽车',
      //           icon: 'icon-bus2'
      //         },
      //         {
      //           name: '货车',
      //           icon: 'icon-truck2'
      //         },
      //         {
      //           name: '摩托车',
      //           icon: 'icon-motorcycle1'
      //         },
      //         {
      //           name: '货运从业知识',
      //           icon: 'icon-trucks3'
      //         }
      //       ]
      //   },
      //   {
      //     id: 2,
      //     title: '资格证',
      //     describe: '3D模拟、高清视频、flash动画多形式，将理论知识可视化。',
      //     children: [
      //       {
      //         name: '客运',
      //         icon: 'icon-bus2'
      //       },
      //       {
      //         name: '货运',
      //         icon: 'icon-truck2'
      //       },
      //       {
      //         name: '网约车',
      //         icon: 'icon-car-hailing'
      //       },
      //       {
      //         name: '出租车',
      //         icon: 'icon-taxi1'
      //       },
      //       {
      //         name: '危险品',
      //         icon: 'icon-dangerous'
      //       }
      //     ]
      //   },
      //   {
      //     id: 3,
      //     title: '继续教育',
      //     describe: '多终端学习，多教学方法，课程生动直观、易掌握。',
      //     children: [
      //       {
      //         name: '客运',
      //         icon: 'icon-bus2'
      //       },
      //       {
      //         name: '货运',
      //         icon: 'icon-truck2'
      //       },
      //       {
      //         name: '教练员',
      //         icon: 'icon-coach'
      //       },
      //       {
      //         name: '出租车',
      //         icon: 'icon-taxi1'
      //       },
      //       {
      //         name: '危险品',
      //         icon: 'icon-dangerous'
      //       }
      //     ]
      //   },
      //   {
      //     id: 4,
      //     title: '满分与审验教育',
      //     describe: '大量事故案例教学，提升安全文明驾驶意识。',
      //     children: [
      //       {
      //         name: '满12分教育',
      //         icon: 'icon-full-12'
      //       },
      //       {
      //         name: '审验教育',
      //         icon: 'icon-cd-education'
      //       }
      //     ]
      //   }
      // ]
    }
  },
  components: {},
  created() {
    this.getlicenceData()
  },
  methods: {
    getlicenceData() {
      getlicence().then(res => {
        if (res.code === 0) {
          this.licenceData = res.result
          for (let i = 0; i < this.licenceData.length; i++) {
            if (this.licenceData[i].hasOwnProperty('children') === true) {
              for (let s = 0; s < this.licenceData[i].children.length; s++) {
                if (this.licenceData[i].children[s].classname === null) {
                  this.licenceData[i].children[s].classname = 'icon-car2'
                }
              }
            }
          }
        }
      })
    }
  }
}
</script>
